import PropTypes from 'prop-types';

const infoBoxShape = PropTypes.shape({
  imagePath: PropTypes.string.isRequired,
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
});

export { infoBoxShape };

import React from 'react';
import PropTypes from 'prop-types';
import { OffersListWrapper, OffersListUl } from './OffersList.styles';
import OffersListItem from './components/OffersListItem/OffersListItem';
import { OffersListShapes } from './components/OffersListItem/OffersListItem.shapes';

const OffersList = ({ items, isPromotionsActive }) => (
  <OffersListWrapper>
    <OffersListUl>
      {
        items.map((value, key) => (
          !(key === (items.length - 1)) && !value.hideInvestmentOnFront && (
            <OffersListItem
              item={value}
              key={key}
              isPromotion={key === (items.length - 1)}
              isPromotionActive={isPromotionsActive}
            />
          )
        ))
      }
      {
        isPromotionsActive ? (
          <OffersListItem
            item={items[items.length - 1]}
            isPromotion
          />
        ) : null
      }
    </OffersListUl>
  </OffersListWrapper>
);

OffersList.propTypes = {
  isPromotionsActive: PropTypes.number.isRequired,
  items: PropTypes.arrayOf(OffersListShapes).isRequired,
};

OffersList.defaultProp = {
  items: [],
};

export default OffersList;

import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'next/router';
import Provider from 'components/Provider';
import { overrideCache } from 'utils/cacheRequest';

import { loadSharedData } from 'containers/sharedStore';
import { loadFrontData } from 'containers/Home/store';
import { loadData as loadPromotionsData } from 'containers/PromotionsList/store';
import Home from 'containers/Home';

class HomePage extends React.Component {
  static propTypes = {
    router: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    overrideData: PropTypes.array, // eslint-disable-line react/forbid-prop-types
  };

  static defaultProps = {
    overrideData: undefined,
  };

  static getInitialProps = async ({ reduxStore, req }) => {
    const sharedData = await reduxStore.dispatch(loadSharedData());
    const frontData = await reduxStore.dispatch(loadFrontData());
    const promotionsData = await reduxStore.dispatch(loadPromotionsData());

    if (req) {
      return {
        overrideData: [
          ...sharedData,
          ...frontData,
          ...promotionsData,
        ],
      };
    }

    return null;
  };

  componentDidMount() {
    // When the page is server-rendered,
    // we override the value in the client cache
    if (this.props.overrideData) {
      overrideCache(this.props.overrideData);
    }
  }


  render() {
    return <Home url={this.props.router} date={new Date().getTime()} />;
  }
}

export default withRouter(Provider(HomePage));

import React from 'react';

const MountComponentPage = (Page) => class MountComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = { mounted: false };
  }

  componentDidMount() {
    this.setState({ mounted: true }); // eslint-disable-line react/no-did-mount-set-state
  }

  render() {
    return (
      <Page {...this.props} mountedComponent={this.state.mounted} />
    );
  }
};

export default MountComponentPage;

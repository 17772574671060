import React from 'react';
import { connect } from 'react-redux';
import HomePage from 'components/pages/Home';
import selector from './selector';

const Home = (props) => (
  <HomePage
    {...props}
  />
);

const mapDispatchToProps = {};

export default connect(selector, mapDispatchToProps)(Home);

import { createSelector } from 'reselect';
import _filter from 'lodash/filter';

import SharedSelector, { ContactItems } from 'containers/sharedSelector';
import { PromotionsListDateSelector } from 'containers/PromotionsList/selector';

import config from 'config';

const HomePageSelector = (state) => state.HomePage;

const PromotionsList = createSelector(
  PromotionsListDateSelector,
  (promotions) => promotions.items.slice(0, 3),
);

const ContactsSelector = createSelector(
  ContactItems,
  (contacts) => {
    const contactItems = _filter(
      contacts,
      (contact) => contact.type.id !== config.contact.typeIds.complaintsDepartment,
    );

    return contactItems;
  },
);

export default createSelector(
  SharedSelector,
  HomePageSelector,
  PromotionsList,
  ContactsSelector,
  (shared, homePage, promotionsList, contactItems) => ({
    ...shared,
    ...homePage,
    contactItems,
    promotionsList,
  }),
);

import PropTypes from 'prop-types';

const descriptionShape = PropTypes.shape({
  address: PropTypes.string,
  realizationDate: PropTypes.string,
});

export const OffersListShapes = PropTypes.shape({
  id: PropTypes.number.isRequired,
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: descriptionShape,
  color: PropTypes.string,
  goTo: PropTypes.shape({
    route: PropTypes.string,
    params: PropTypes.shape(
      {
        id: PropTypes.number,
      },
    ),
  }),
});

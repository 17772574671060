import React from 'react';
import PropTypes from 'prop-types';

import PageLayout from 'components/layouts/PageLayout';
import InvestmentOffers from 'components/content/InvestmentOffers';
import InfoPanel from 'components/content/InfoPanel';

import { InvestmentShape } from 'components/content/InvestmentOffers/InvestmentOffers.shapes';
import { infoBoxShape } from 'components/content/InfoPanel/InfoPanel.shapes';

const Home = ({
  statisticsItems,
  investmentOffersItems,
  promotionsList,
  ...restProps
}) => (
  <PageLayout showMap {...restProps}>
    <InvestmentOffers key="investmentOffers" items={investmentOffersItems} isPromotionsActive={promotionsList.length} />
    <InfoPanel key="infoPanel" data={statisticsItems} />
  </PageLayout>
);

Home.propTypes = {
  investmentOffersItems: PropTypes.arrayOf(InvestmentShape),
  statisticsItems: PropTypes.arrayOf(infoBoxShape),
};

Home.defaultProps = {
  investmentOffersItems: [],
  statisticsItems: [],
};

export default Home;

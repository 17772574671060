import React from 'react';
import PropTypes from 'prop-types';

import withSizes from 'utils/withSizes';
import MountComponent from 'components/MountComponent';
import InfoBox from './components/InfoBox';
import { infoBoxShape } from './InfoPanel.shapes';
import { Wrapper, Container } from './InfoPanel.styles';

const InfoPanel = ({ data }) => (
  <Wrapper>
    <Container>
      {data.map((el, key) => (
        <InfoBox
          key={key}
          {...el}
        />
      ))}
    </Container>
  </Wrapper>
);

InfoPanel.propTypes = {
  data: PropTypes.arrayOf(infoBoxShape),
};

InfoPanel.defaultProps = {
  data: [],
};

export default withSizes()(MountComponent(InfoPanel));

import { defineMessages } from 'react-intl';

export default defineMessages({
  description: {
    id: 'InvestmentSlider.description',
    defaultMessage: 'Wysoka jakość i funkcjonalność realizowanych przez nas obiektów',
  },
  titleFirstPart: {
    id: 'InvestmentSlider.titleFirstPart',
    defaultMessage: 'Sprawdź',
  },
  titleSecondPart: {
    id: 'InvestmentSlider.titleSecondPart',
    defaultMessage: 'naszą ofertę',
  },
});
